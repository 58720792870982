<template>
  <div class="list-type1">
    <div class="item"
         v-for="(date, index) in uniqGameStartTime"
         :key="'gameIdx'+index"
    >
      <template v-for="(league, leagueIdx) in timeLeagueSort(date)">
        <div class="title" style="background-color: #262a2b;" :key="'leagueTitle'+ league + leagueIdx">
          <img v-if="lists.find(e => e.leagueName === league).sportsName === '축구'"  src="@/assets/img/ico/1.png">
          <img v-if="lists.find(e => e.leagueName === league).sportsName === '야구'"  src="@/assets/img/ico/11.png">
          <img v-if="lists.find(e => e.leagueName === league).sportsName === '농구'"  src="@/assets/img/ico/3.png">
          <img v-if="lists.find(e => e.leagueName === league).sportsName === '배구'"  src="@/assets/img/ico/5.png">
          <img v-if="lists.find(e => e.leagueName === league).sportsName === '아이스하키'"  src="@/assets/img/ico/2.png">
          <img v-if="lists.find(e => e.leagueName === league).sportsName === '이스포츠'"  src="@/assets/img/ico/77.png">
          <img v-if="lists.find(e => e.leagueName === league).sportsName === '미식축구'"  src="@/assets/img/ico/6.png">
          <img v-if="lists.find(e => e.leagueName === league).league" :src="lists.find(e => e.leagueName === league).league?.img" style="margin-left: 10px; max-width: 30px; height: auto;" alt="">
          <!--          <img v-if="lists.find(e => e.leagueName === league).sportsName === '축구'"-->
          <!--              :src="lists.find(e => e.leagueName === league)."-->
          <!--              style="width: 15px;"-->
          <!--          >-->

          <span class="mr10" style="margin-right: 10px; margin-left: 10px;">
            {{ league }}
          </span>
        </div>

        <!--        <template v-for="(game, index3) in gameTimeLeagueSort(date, league)">-->
        <!--          <div-->
        <!--              :class="{'before': !checkArray(game.seq), 'after': checkArray(game.seq)}"-->
        <!--              :key="'gameIndex'+ index2"-->
        <!--          >-->
        <!--            <transition name="fade" :key="'koreanFolders' + index2">-->
        <!--              <div class="box" :key="'koreanFolders'+ game.seq +index2"-->
        <!--                   v-show="index2 === 0 || checkArray(game)"-->
        <!--              >-->
        <!--                <div class="date">-->
        <!--                  <p>-->
        <!--                    &nbsp;-->
        <!--                    <span style="color: white; margin-left:10px; font-size:15px;"><strong>{{ game.gameDate | formatDate('YY-MM-DD')}}</strong></span>-->
        <!--                    <span style="color: #da2c36; margin-left: 3px; font-size:15px;"><strong>{{ game.gameDate | formatDate('HH:mm')}}</strong></span>-->
        <!--                  </p>-->
        <!--                </div>-->
        <!--                <div class="type">-->
        <!--                  {{ game.betType === 'underover' ? '오버언더' : '핸디캡' }}-->
        <!--                </div>-->
        <!--                <div class="bet-box" >-->
        <!--                  <dl class="home" :class="{'active': checkCart(game, 'home')}" @click="addCart(game, 'home')">-->
        <!--                    <dt >-->
        <!--                      {{ game.teamName1 }}-->
        <!--                    </dt>-->
        <!--                    <dd>-->
        <!--                        <span class="ib">-->
        <!--                          <img v-if="game.betType === 'handicap' && checkCart(game, 'home')" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/white/h.png" alt="">-->
        <!--                          <img v-if="game.betType === 'handicap' && !checkCart(game, 'home')" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/red/h.png" alt="">-->
        <!--                          <img v-if="game.betType === 'underover'" style="width: 9px; height: 8px; margin-top: -3px;" src="@/assets/img/ico/m_ico_up.png" alt="">-->
        <!--                        </span>-->
        <!--                      <span class="orange ib">{{ game.oddsTeam1 }}</span>-->
        <!--                    </dd>-->
        <!--                  </dl>-->
        <!--                  &lt;!&ndash;                    <DetailPrice&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :bets="folder.bets.find(e => e.bets_name === '1' || e.bets_name === 'W1' || e.bets_name === 'Over' || e.bets_name === 'Home')"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :folder="folder"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :add-cart="addCart"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :check-cart="checkCart"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :game="game"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :team="game.home_team"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    >&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    </DetailPrice>&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    <DetailPrice&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        v-if="folder.bets.find(e => e.bets_name === 'X')"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :bets="folder.bets.find(e => e.bets_name === 'X')"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :folder="folder"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :add-cart="addCart"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :check-cart="checkCart"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :game="game"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :team="game.home_team"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    >&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    </DetailPrice>&ndash;&gt;-->
        <!--                  <div class="vs orange&#45;&#45;text">-->
        <!--                    {{ game.oddsTeam3  }}-->
        <!--                  </div>-->
        <!--                  &lt;!&ndash;                    <DetailPrice&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :bets="folder.bets.find(e => e.bets_name === '2' || e.bets_name === 'W2' || e.bets_name === 'Under' || e.bets_name === 'Away' || e.bets_name === 'Even')"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :folder="folder"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :add-cart="addCart"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :check-cart="checkCart"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :game="game"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                        :team="game.away_team"&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    >&ndash;&gt;-->
        <!--                  &lt;!&ndash;                    </DetailPrice>&ndash;&gt;-->
        <!--                  <dl class="away" @click="addCart(game, 'away')" :class="{'active': checkCart(game, 'away')}">-->
        <!--                    <dt >-->
        <!--                      {{ game.teamName2 }}-->
        <!--                    </dt>-->
        <!--                    <dd>-->
        <!--                      <span class="orange ib">{{ game.oddsTeam2 }}</span>-->
        <!--                      <span class="ib">-->
        <!--                          <img v-if="game.betType === 'underover'" style="width: 9px; height: 8px; margin-top: -3px;" src="@/assets/img/ico/m_ico_down.png" alt="">-->
        <!--                          <img v-if="game.betType === 'handicap' && checkCart(game, 'away')" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/white/h.png" alt="">-->
        <!--                          <img v-if="game.betType === 'handicap' && !checkCart(game, 'away')" style="width: 8px; margin-top: -2px;" src="@/assets/img/ico/red/h.png" alt="">-->
        <!--                        </span>-->
        <!--                    </dd>-->
        <!--                  </dl>-->
        <!--                </div>-->
        <!--                <div class="status">-->
        <!--                  <CountDown :startDate="game.gameDate"></CountDown>-->
        <!--                </div>-->
        <!--                <div class="toggle" v-if="index2 === 0">-->
        <!--                  <button-->
        <!--                      style="background-color:transparent; color: #D82731; font-weight: bold;"-->
        <!--                      :class="{'active':checkArray(game)}"-->
        <!--                      @click="toggleDetail(game)"-->
        <!--                  >-->
        <!--                    + {{ countFolder(game) }}-->
        <!--                  </button>-->
        <!--                </div>-->
        <!--                <div class="toggle" v-else></div>-->
        <!--              </div>-->
        <!--            </transition>-->
        <!--          </div>-->
        <!--        </template>-->
        <template v-for="team in gameTimeTeam(date, league)">
          <template v-for="(game, index2) in gameTimeLeagueSort(date, league, team)">
            <div
                :class="{'before': !checkArray(game), 'after': checkArray(game)}"
                :key="'gameIndex'+ game.seq"
            >
              <transition name="fade" :key="'koreanFolders' + index2">
                <div class="box" :key="'koreanFolders'+ game.seq +index2"
                     v-show="index2 === 0 || checkArray(game)"
                >
                  <div class="date">
                    <p>
                      &nbsp;
                      <span style="color: white; font-size:15px;"><strong>{{ game.gameDate | formatDate('YY-MM-DD')}}</strong></span>
                      <span style="color: #0d6c9a; margin-left: 3px; font-size:15px;"><strong>{{ game.gameDate | formatDate('HH:mm')}}</strong></span>
                    </p>
                  </div>
                  <div class="type">
                    {{ game.betType === 'underover' ? '오버언더' : '핸디캡' }}
                  </div>
                  <div class="bet-box" >
                    <dl class="home" :class="{'active': checkCart(game, 'home')}" @click="addCart(game, 'home')">
                      <dt>
                        {{ game.teamName1 }}
                      </dt>
                      <dd>
                        <span class="ib">
                          <img v-if="game.betType === 'handicap' && checkCart(game, 'home')" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/white/h.png" alt="">
                          <img v-if="game.betType === 'handicap' && !checkCart(game, 'home')" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/red/h.png" alt="">
                          <img v-if="game.betType === 'underover'" style="width: 9px; height: 8px; margin-top: -3px;" src="@/assets/img/ico/m_ico_up.png" alt="">
                        </span>
                        <span class="orange--text ib">{{ game.oddsTeam1 }}</span>
                      </dd>
                    </dl>
                    <!--                    <DetailPrice-->
                    <!--                        :bets="folder.bets.find(e => e.bets_name === '1' || e.bets_name === 'W1' || e.bets_name === 'Over' || e.bets_name === 'Home')"-->
                    <!--                        :folder="folder"-->
                    <!--                        :add-cart="addCart"-->
                    <!--                        :check-cart="checkCart"-->
                    <!--                        :game="game"-->
                    <!--                        :team="game.home_team"-->
                    <!--                    >-->
                    <!--                    </DetailPrice>-->
                    <!--                    <DetailPrice-->
                    <!--                        v-if="folder.bets.find(e => e.bets_name === 'X')"-->
                    <!--                        :bets="folder.bets.find(e => e.bets_name === 'X')"-->
                    <!--                        :folder="folder"-->
                    <!--                        :add-cart="addCart"-->
                    <!--                        :check-cart="checkCart"-->
                    <!--                        :game="game"-->
                    <!--                        :team="game.home_team"-->
                    <!--                    >-->
                    <!--                    </DetailPrice>-->
                    <div class="vs orange--text">
                      {{ game.oddsTeam3  }}
                    </div>
                    <!--                    <DetailPrice-->
                    <!--                        :bets="folder.bets.find(e => e.bets_name === '2' || e.bets_name === 'W2' || e.bets_name === 'Under' || e.bets_name === 'Away' || e.bets_name === 'Even')"-->
                    <!--                        :folder="folder"-->
                    <!--                        :add-cart="addCart"-->
                    <!--                        :check-cart="checkCart"-->
                    <!--                        :game="game"-->
                    <!--                        :team="game.away_team"-->
                    <!--                    >-->
                    <!--                    </DetailPrice>-->
                    <dl class="away" @click="addCart(game, 'away')" :class="{'active': checkCart(game, 'away')}">
                      <dt >
                        {{ game.teamName2 }}
                      </dt>
                      <dd>
                        <span class="orange--text ib">{{ game.oddsTeam2 }}</span>
                        <span class="ib">
                          <img v-if="game.betType === 'underover'" style="width: 9px; height: 8px; margin-top: -3px;" src="@/assets/img/ico/m_ico_down.png" alt="">
                          <img v-if="game.betType === 'handicap' && checkCart(game, 'away')" style="width: 8px; margin-top: -3px;" src="@/assets/img/ico/white/h.png" alt="">
                          <img v-if="game.betType === 'handicap' && !checkCart(game, 'away')" style="width: 8px; margin-top: -2px;" src="@/assets/img/ico/red/h.png" alt="">
                        </span>
                      </dd>
                    </dl>
                  </div>
                  <div class="status">
                    <CountDown :startDate="game.gameDate"></CountDown>
                  </div>
                  <div class="toggle" v-if="index2 === 0">
                    <button
                        style="background-color:transparent; color: #0d6c9a; font-weight: bold;"
                        :class="{'active':checkArray(game)}"
                        @click="toggleDetail(game)"
                    >
                      + {{ countFolder(game, team) }}
                    </button>
                  </div>
                  <div class="toggle" v-else></div>
                </div>
              </transition>
            </div>
          </template>
        </template>
      </template>


    </div>
  </div>
</template>

<script>
import CountDown from "./CountDown";

export default {
  name: "Korean",
  props: ['lists', 'cartType', 'addCart', 'checkCart'],
  components: {
    CountDown,
  },
  data: function () {
    return {
      openDetailBet: [],
    }
  },
  computed: {
    uniqGameStartTime: function(){
      const dates = this.lists.map(e => e.gameDate);
      const time = this.$options.filters.uniq(dates)
      // console.log(time);
      return time;
    }
  },
  created() {
    this.sortLeagues()
  },
  methods: {
    /*
    * 종목의 자세히 버튼
    */
    checkArray: function (game) {
      let result = this.openDetailBet.filter(g => g.leagueName === game.leagueName && g.gameDate === game.gameDate && g.teamName1 === game.teamName1).length > 0
      return result
    },
    /*
     * 토글 액션
     */
    toggleDetail: function (game) {
      this.toggle(this.openDetailBet, game)
    },
    removeAtIndex: function (arr, index) {
      // const copy = [...arr];
      this.openDetailBet.splice(index, 1);
      // return this.openDetailBet;
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    timeLeagueSort: function(date){
      const games = this.lists.filter(e => e.gameDate === date);
      const leagues = games.map(e => e.leagueName);
      const leaguesName = this.$options.filters.uniq(leagues);
      return leaguesName;
    },
    sortLeagues: function(){
      for(const u in this.uniqGameStartTime){
        this.timeLeagueSort(this.uniqGameStartTime[u]);
      }
    },
    gameTimeLeagueSort: function(date, league, team){
      const games = this.lists.filter(e => e.gameDate === date && e.leagueName === league && e.teamName1 === team);
      return games;
    },
    gameTimeTeam: function(date, league){
      const games = this.lists.filter(e => e.gameDate === date && e.leagueName === league);
      const teams = games.map(e => e.teamName1);
      const teamNames = this.$options.filters.uniq(teams);
      return teamNames;
    },
    countFolder: function(game){
      return this.lists.filter(g => g.leagueName === game.leagueName && g.gameDate === game.gameDate && g.teamName1 === game.teamName1).length
    }
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
.active span{
  color: #FFFFFF !important;
}
</style>
