import { render, staticRenderFns } from "./MWrapperFolderLsport.vue?vue&type=template&id=2e5eb10e&scoped=true"
import script from "./MWrapperFolderLsport.vue?vue&type=script&lang=js"
export * from "./MWrapperFolderLsport.vue?vue&type=script&lang=js"
import style0 from "./MWrapperFolderLsport.vue?vue&type=style&index=0&id=2e5eb10e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e5eb10e",
  null
  
)

export default component.exports