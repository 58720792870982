import axios from 'axios';
import store from '../index';

const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    dateCnt: [],
    cart: [],
  }
}

const state = getDefaultState()

const getters = {
  //카트 안에 담긴 배당내역의 총합
  SPORT_TOTAL_ODDS: function(state) {
    try {
      return Math.floor(state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1) * 100) / 100;
    } catch (error) {
      // 에러 발생 대비로 캐치블럭에 변경정 로직
      return state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1).toFixed(2);
    }
  },
}

const actions = {
  GET_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/game/european/list3`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_KOREAN_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/game/korean/prematch2`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_KOREAN_SPECIAL_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/game/korean/special2`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LIST', response.data?.payload.list)
        }
        return response
      })
  },
  GET_DATE_CNT({commit}, params) {
    return axios.post(`${API_URL}/v2/game/european/dateCnt`, params)
      .then((response) => {
        if (response.data){
          commit('SET_DATE', response.data?.payload)
        }
        return response
      })
  },
  GET_DETAIL(_, params) {
    return axios.post(`${API_URL}/v2/game/european/detail2`, params)
      .then((response) => {
        return response
      })
  },
  ADD_CART_ITEM({state, commit, getters}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      commit('POP_ITEM_CART_BY_FOLDER', obj);
      handleBonusItem(state, commit)
      return; // 있을시 삭제
    }

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.SPORT_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const cartItem = state.cart.find(item => item.betsSeq === obj.betsSeq);
    if (!cartItem) {
      // 추가할 item 이 카트의 게임과 일치하지 않을 경우, 카트에 새로 추가
      // 동일경기는 기본적인 동일경기 설정
      const dupItem = state.cart.filter(item => item.gameId === obj.gameId);
      // 조합 가능 구분 여부
      const betOption = store.state.setting.settings.sportConfig.combination.filter(e=> e.status === '사용');

      // const betOption =  state.bettingOptions.filter(e => e.list === true)
      const sportsBetOption = betOption.filter(e => Number(e.sport) === obj.sportsId)
      // console.log(sportsBetOption)
      if ( dupItem.length >= 2 ) {
        return alert('동일경기에서 3가지 이상 조합은 불가능합니다.')
      }

      if (dupItem.length > 0) {
        // 종목별 조합 검증항목 배열에 담음
        const cartItemInMarketId = dupItem.map(e => Number(e.marketsId));
        cartItemInMarketId.push(Number(obj.marketsId));
        const verifyResult = [];
        // console.log(sportsBetOption)
        const alloweds = sportsBetOption.map(e => e.allowed);
        for (const i in alloweds){
          let verify = alloweds[i].filter(e => e !== null);
          let inResult = [];
          for (const j in verify){
            let combination = verify[j].id;
            inResult.push(cartItemInMarketId.indexOf(combination));
          }
          verifyResult.push(inResult);
        }

        // 배열에 담긴 조합 검증항목을 체크
        let res = false;
        for (const v in verifyResult) {
          const verify = verifyResult[v];
          if (verify.indexOf(-1) === -1){
            res = true;
          }
        }
        if (!res){
          store.state.modals.errorMsg = '동일경기 불가능한 조합입니다.';
          store.state.modals.deduction = true;
          return;
        }

        // 축구일때 승무패 배당차감
        const soccerOption = store.state.setting.settings.sportConfig.common.soccerCombination;
        if (obj.sportsId === 210 && soccerOption.active) {
          const SMP = state.cart.find(e => e.marketNameKr.indexOf('승무패') >= 0 && e.gameId === obj.gameId);
          if ((SMP && SMP.betsName === 'X') || obj.betsName === 'X') {
            store.state.modals.errorMsg = '해당 조합은 베팅이 불가합니다.';
            store.state.modals.deduction = true;
            return
          }

          store.state.modals.errorMsg = '축구 동일경기 조합베팅시 차감 배당값으로 적용됩니다.';
          store.state.modals.deduction = true;

          // 이미 승무패가 등록되어있을시
          if (SMP){
            if (Number(SMP.odds) >= 1.01 && Number(SMP.odds) < 1.50) {
              SMP.betsPrevOdds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
              SMP.odds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
            } else if (Number(SMP.odds) >= 1.51 && Number(SMP.odds) < 1.99) {
              SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
              SMP.odds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
            } else {
              SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
              SMP.odds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
            }
          }
          // 승무패가 후에 추가될시
          if (obj.marketNameKr.indexOf('승무패') >= 0) {
            if (Number(obj.odds) >= 1.01 && Number(obj.odds) < 1.50) {
              obj.betsPrevOdds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
              obj.odds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
            } else if (Number(obj.odds) >= 1.51 && Number(obj.odds) < 1.99) {
              obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
              obj.odds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
            } else {
              obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
              obj.odds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
            }
          }
        }
      }
      commit('PUSH_ITEM_CART', obj);
      handleBonusItem(state, commit)
    } else {
      commit('POP_ITEM_CART', obj); // 있을시 삭제
    }
  },
  ADD_EUROPEAN_CART_ITEM({state, commit, getters}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      commit('POP_ITEM_CART_BY_FOLDER', obj);
      // handleBonusItem(state, commit)
      return; // 있을시 삭제
    }

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.SPORT_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const dupItem = state.cart.filter(item => item.gameId === obj.gameId);
    if ( dupItem.length > 0 ) {
      return alert('동일경기에서 조합은 불가능합니다.')
    }

    commit('PUSH_ITEM_CART', obj);
    // handleBonusItem(state, commit)
  },
  DELETE_CART_ITEM({ commit }, obj){
    commit('POP_ITEM_CART', obj); // 있을시 삭제
    handleBonusItem(state, commit)
  },
  DELETE_CART_ITEM_GAME({commit}, gameId){
    commit('POP_ITEM_CART_BY_GAME', gameId); // 있을시 삭제
  },
  ALL_CLEAR_ITEM({ commit }){
    return commit('RESET_SPORTS_CART')
  },
  CLEAR_SPORT_CART({commit}, type){
    return commit('RESET_TYPE_CART', type)
  },
  UPDATE_DATA({commit}, data){
    commit('SET_DETAIL_DATA', data)
  },
}

const mutations = {
  SET_LIST(state, payload) {
    state.originList = payload
  },
  SET_DATE(state, payload) {
    state.dateCnt = payload
  },
  PUSH_ITEM_CART(state, obj){
    state.cart.push(obj)
  },
  POP_ITEM_CART_INDEX(state, index){
    return state.cart.splice(index, 1);
  },
  POP_ITEM_CART_BY_FOLDER(state, obj){
    const index = state.cart.findIndex(i => i.foldersSeq === obj.foldersSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_CART(state, obj){
    const index = state.cart.findIndex(i => i.betsSeq === obj.betsSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_ITEM_CART_BY_GAME(state, gameId){
    const index = state.cart.findIndex(i => i.gameId === gameId);
    if (index === -1) {
      state.cart = [...state.cart ]
      return;
    }
    return state.cart.splice(index, 1);
  },
  RESET_TYPE_CART(state, type = '프리매치'){
    state.cart = state.cart.filter(e => e.betType === type);
  },
  RESET_SPORTS_CART(state){
    state.cart = [];
  },
  SET_DETAIL_DATA(state, data) {
    // console.log(data.data)
    const result = data.data;
    const game = state.originList.find(game => game.id === result.game.id)
    if (game){
      const gameFolder = game.folders[0];
      if(gameFolder){
        const folder = result.list.find(folder => String(folder.id) === String(gameFolder.id));
        gameFolder.bets.map((bet) => {
          folder.bets.map(recentBet => {
            if (recentBet.id === bet.id) bet.price = recentBet.price;
          })
        })
      }
    }
  }
}

function handleBonusItem(state, commit) {
  const cartItem = state.cart.filter(e => e.betType !== '보너스');
  // 장바구니 아이템 수에 따른 보너스 배당률 설정
  const minOddsOption = store.state.setting.settings.config.option.siteOption2.find(e => e.name === '보너스최소배당');
  const minOdds = minOddsOption ? Number(minOddsOption.value) : 1.3; // 기본값 설정

  // 최소 배당 조건을 만족하지 않는 항목이 있는지 확인
  const hasBelowMinOdds = cartItem.some(item => Number(item.odds) < minOdds);
  
  let bonusRate = 1;
  let bonusName = null;
  if (cartItem.length >= 3 && cartItem.length < 5) {
      bonusRate = 1.03;
      bonusName = 3;
  } else if (cartItem.length >= 5 && cartItem.length < 7) {
      bonusRate = 1.05;
      bonusName = 5;
  } else if (cartItem.length >= 7) {
      bonusRate = 1.07;
      bonusName = 7;
  }
  console.log(bonusRate, bonusRate !== undefined, 'delete');
  // 보너스 아이템 정의
  const bonusItem = bonusRate > 1 ? {
      gameId: null,
      betsSeq: null,
      betsName: '1',
      sportsId: null,
      marketsId: null,
      foldersSeq: null,
      odds: bonusRate,
      betsUpdated: false,
      betsPrevOdds: bonusRate,
      line: null,
      leagueNameKr: '보너스',
      leagueNameEn: '보너스',
      leagueNameIcon: null,
      sportNameKr: '보너스',
      sportNameEn: '보너스',
      sportNameIcon: null,
      homeNameKr: `${bonusName}폴 보너스`,
      homeNameEn: `${bonusName}폴 보너스`,
      awayNameKr: '보너스',
      awayNameEn: '보너스',
      marketNameKr: '보너스',
      marketNameEn: '보너스',
      startTime: null,
      odds1: bonusRate,
      odds2: bonusRate,
      odds3: bonusRate,
      betType: '보너스',
      detailType: null,
      currentPeriod: 0,
      currentTime: 0,
      homeScore: 0,
      awayScore: 0,
      penalty: null,
      penaltyReason: null,
  } : null;

  // 장바구니에 보너스가 이미 있는지 확인
  const bonusIndex = state.cart.findIndex(cartItem => cartItem.betType === '보너스');
  console.log(bonusRate, 'pop');
  // 보너스 추가 또는 제거
  if (bonusRate > 1 && !hasBelowMinOdds) { // 최소 배당 조건 추가
    if (bonusIndex !== -1) {
      // 기존 보너스 제거
      state.cart.splice(bonusIndex, 1);
    } 
    if (bonusItem) commit('PUSH_ITEM_CART', bonusItem);
  } else if (bonusIndex !== -1) {
      // 보너스가 필요 없을 때 제거
      state.cart.splice(bonusIndex, 1);
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
