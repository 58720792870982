<template>
  <section v-if="!this.$store.state.page.isMobile" style="min-height: 1800px;">
    <div class="gameBox" v-if="url">
      <iframe scrolling="no" frameborder="0"
              style="width: 100%; height: 1800px;
          margin:0px 0px 0px 0px; z-index: 1; border: none;"
              allowFullScreen="true"
              :src="url">
      </iframe>
    </div>
  </section>
  <div class="mini-game" v-else style="min-height: 700px;">
    <div class="game-wrap" style="height: 122px;">
      <iframe scrolling="no" frameborder="0"
        style="width: 100%; height: 650px;
        margin:0px 0px 0px 0px; z-index: 1; border: none;"
        allowFullScreen="true"
        :src="url">
      </iframe>
    </div>
  </div>
</template>

<script>

export default {
  name: "Keno",
  components: {
  },
  data: function(){
    return {
      path: 'tk/user',
      url: null
    }
  },
  created: function(){
    this.initGame();
  },
  methods: {
    initGame: async function(){
      return this.$store.dispatch('REQ_TKSPORT_INFO')
          .then(data => {
            const result = data.data;
            console.log(result.data.url);
            // this.hash = result.hash;
            // this.uid = result.uid;
            // this.initUrl()
            this.url = result.data.url;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    initUrl: function(){
      this.url = 'https://api.tgame365.com/api/';
      this.url += `?gType=${this.gType}`;
      this.url += `&uid=${this.uid}`;
      this.url += `&hash=${this.hash}`;

    }
  }
}
</script>

<style scoped>

</style>
