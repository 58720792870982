<template>
  <div>
    <div class="header2">
      <div class="left">
        <router-link to="/login" class="btn-prev"></router-link>
      </div>
      <h2>회원가입</h2>
    </div>

    <div class="m-register">
      <div class="inpWrap">
        <div class="inp">
          <input v-model="signUpForm.userId" type="text" placeholder="아이디(영문 또는 영문+숫자 조합으로 최소 6자 이상, 최대 12자 이하)" >
        </div>
        <div class="inp">
          <input v-model="signUpForm.nickname" type="text" placeholder="닉네임(한글 또는 한글+숫자 조합으로 최소 3자 이상, 최대 12자 이하)">
        </div>
        <div class="inp">
          <input v-model="signUpForm.userPw" type="password" placeholder="비밀번호(8자 이상)" minlength="8">
        </div>
        <div class="inp">
          <input v-model="signUpForm.userPw2" type="password" placeholder="비밀번호 확인" minlength="8">
        </div>
        <div class="inp"><input v-model="signUpForm.cashoutPw" type="password" placeholder="출금비밀번호" maxlength="6"></div>
        <div class="inpSel">
          <div class="selBox">
            <select id="sel01" v-model="signUpForm.bankName">
              <option :value="null">--은행선택--</option>
              <option v-for="(row, index) in bank_name" :key="'bankName' +index" :value="row.name">{{ row.name }}</option>
            </select>
          </div>
          <input type="text" placeholder="예금주" v-model="signUpForm.bankDepositor">
        </div>
        <div class="inp"><input type="text" v-model="signUpForm.bankAccount" placeholder="계좌번호 입력(- 없이 숫자만 입력)"></div>
        <div class="inp"><input type="text" v-model="signUpForm.contact" placeholder="휴대폰 번호(- 없이 숫자만 입력)"></div>
        <div class="inp">
          <input type="text" placeholder="가입코드" v-model="signUpForm.code">
          <p v-if="codeMsg" style="color:#0d6c9a; padding-top: 10px; padding-bottom: 0px;">{{ codeMsg }}</p>
        </div>
      </div>
      <p class="infoTxt">
        추천인 아이디를 제외한 모든 항목은 필수 입력 사항 입니다. <br>
        모든항목을 입력하시면 회원가입 버튼이 활성화 됩니다.
      </p>
      <a  @click="doSignup" class="btn type01">회원가입</a>
<!--      <a @click="alert('모든 항목 기입후 잠시만기다려주세요.')" v-else class="btn">회원가입</a>-->
      <p class="infoTxt">이미 회원이라면? 로그인 후 이용해 주시기 바랍니다.</p>
      <router-link to="/login" class="btn">로그인</router-link>
    </div>

  </div>
</template>

<script>
import MSG from "@/contants/msg";

export default {
  name: "MSignUp",
  data: () => ({
    userId: null,
    userIdMsg: null,
    userPw: null,
    userPw2: null,
    nickname: null,
    nicknameMsg: null,
    code: null,
    codeMsg: null,
    phone: null,
    bankName: null,
    owner: null,
    account: null,
    cashoutPw: null,
    cashoutPw2: null,
    signUpForm: {
      userId: null,
      nickname: null,
      userPw: null,
      contact: null,
      cashoutPw: null,
      bankDepositor: null,
      bankName: null,
      bankAccount: null,
      code: '',
      referrer: '',
    },
    infoAgree: false,
    idPass: false,
    codePass: false,
    nicknamePass: false,
  }),
  watch: {
    'signUpForm.userId': function(val){
      this.userId = val.replace(/[^a-zA-Z0-9]/gi, '')
      const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

      if(reg.exec(val)!==null){
        return this.signUpForm.userId = this.signUpForm.userId.slice(0,-1);
      }
    },
  },
  computed: {
    bank_name: function(){
      return this.$store.getters['BANK_SETTING']
    }
  },
  methods: {
    doSignup: function(){
      if(confirm('가입을 진행하시겠습니까?')) {
        let errMsg = '';
        if (this.signUpForm.userPw !== this.signUpForm.userPw2) errMsg = MSG.error.difPw
        if (!this.signUpForm.userId) errMsg = MSG.error.emptyId;
        if (!this.signUpForm.userPw) errMsg =  MSG.error.emptyPassword;
        if (!this.signUpForm.userPw2) errMsg = MSG.error.emptyPassword;
        if (this.signUpForm.userPw?.length <= 7) errMsg = MSG.error.shortPassword;
        if (!this.signUpForm.nickname) errMsg = MSG.error.emptyNick;
        if (!this.signUpForm.code) errMsg = MSG.error.emptyCode;
        if (!this.signUpForm.contact) errMsg = MSG.error.emptyContact;
        if (!this.signUpForm.bankName) errMsg = MSG.error.emptyBank;
        if (!this.signUpForm.bankDepositor) errMsg = MSG.error.emptyDepositor;
        if (!this.signUpForm.bankAccount) errMsg = MSG.error.emptyBankNum;
        if (!this.signUpForm.cashoutPw) errMsg =  MSG.error.emptyWithdrawalPw;
        if (errMsg !== '') return this.$store.dispatch('SHOW_ERR_MSG', errMsg);
        this.isProcess = true;
        return this.$store.dispatch('SIGNUP', this.signUpForm)
            .then(() => {
              this.$store.dispatch('SHOW_ERR_MSG', MSG.success.signup);
              this.$router.push('/main')
            })
            .catch((error) => {
              this.$store.dispatch('SHOW_ERR_MSG', error.response.data.error);
              // alert(error.response.data.error);
            })
      }
    },
    checkRefererCode: function(){
      const data = {};
      data.payload = {
        code: this.code,
        siteName: 'viking'
      };
      this.$store.dispatch('auth/checkCode', data).then(data => {
        if (data.success) {
          this.codePass = true;
          this.codeMsg = null;
        }
        if (!data.success) {
          this.codePass = false;
          this.codeMsg = data.msg;
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
