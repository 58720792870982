<template>
  <div class="form-container">
    <div class="form-group">
      <label for="category">카테고리</label>
      <select id="category" v-model="payload.category" style="padding: -10px">
        <option
          v-for="option in categoryOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="title">제목</label>
      <input
        type="text"
        id="title"
        placeholder="제목입력"
        v-model="payload.title"
      />
    </div>
    <div class="form-group">
      <label for="content">내용</label>
      <textarea
        id="content"
        placeholder="내용입력"
        v-model="payload.content"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="betting">베팅내역첨부</label>
      <div class="form-actions" v-if="submitText !== '수정하기'">
        <button class="add-bet" @click="openList">베팅내역 추가</button>
        <button class="clear-bet" @click="deleteBetting">
          베팅내역 전부삭제
        </button>
      </div>
      <div class="table-container">
        <table
          v-if="payload.bettings?.length"
          id="tableAll1"
          cellspacing="0"
          cellpadding="0"
          width="100%"
          class="dataTable no-footer"
          role="grid"
          style="width: 100%; display: block;"
        >
          <thead>
            <tr role="row">
              <th class="sorting" style="width: 220px">베팅 NO</th>
              <th
                class="sorting_desc"
                style="width: 120px"
                aria-sort="descending"
              >
                베팅 일시
              </th>
              <th class="sorting_asc" style="width: 130px">베팅 종목</th>
              <th class="sorting" style="width: 130px">베팅 결과</th>
              <th class="sorting" style="width: 168px">배팅 금액</th>
              <th class="sorting" style="width: 143px">배당율</th>
              <th class="sorting" style="width: 144px">예상 적중 금액</th>
              <th class="sorting" style="width: 71px">폴더</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(row, index) in payload.bettings">
              <tr
                id="tableall1_2802777"
                role="row"
                class="odd"
                :key="'bettingTitle' + index"
              >
                <td @click="toggle(index)">
                  {{ row[0].betting_betcode }} <br />
                  {{ row[0].betting_type }}
                </td>
                <td @click="toggle(index)" class="sorting_1">
                  {{
                    row[0].betting_regdatetime
                      | formatDate("YYYY-MM-DD HH:mm:ss")
                  }}
                </td>
                <td @click="toggle(index)">
                  {{
                    row[0].betting_game_sports_name_kr ||
                    row[0].betting_game_sports_name_en
                  }}
                  {{ row.length > 1 ? "외" : "" }}
                </td>
                <td
                  @click="toggle(index)"
                  v-if="row[0].betting_status.indexOf('취소') < 0"
                >
                  <span
                    :class="{
                      'text-yellow':
                        row[0].betting_total_result === 'exception',
                      'text-red': row[0].betting_total_result === 'miss',
                      'text-green': row[0].betting_total_result === 'hit',
                      'text-gray': row[0].betting_total_result === 'wait',
                    }"
                  >
                    <template v-if="row[0].betting_total_result === 'hit'">
                      {{ row[0].betting_payed === "y" ? "당첨" : "지급대기" }}
                    </template>
                    <template v-else>
                      {{ row[0].betting_total_result | bettingStatus }}
                    </template>
                  </span>
                </td>
                <td v-else>취소</td>
                <td @click="toggle(index)">
                  <span
                    :class="{
                      'text-yellow':
                        row[0].betting_total_result === 'exception',
                      'text-red': row[0].betting_total_result === 'miss',
                      'text-green': row[0].betting_total_result === 'hit',
                      'text-gray': row[0].betting_total_result === 'wait',
                    }"
                    >{{ row[0].betting_bet_amount | makeComma }}</span
                  >
                </td>
                <td @click="toggle(index)">
                  <span
                    :class="{
                      'text-yellow':
                        row[0].betting_total_result === 'exception',
                      'text-red': row[0].betting_total_result === 'miss',
                      'text-green': row[0].betting_total_result === 'hit',
                      'text-gray': row[0].betting_total_result === 'wait',
                    }"
                    >{{ Number(row[0].betting_total_odds).toFixed(2) }}
                  </span>
                </td>
                <td @click="toggle(index)">
                  <span
                    :class="{
                      'text-yellow':
                        row[0].betting_total_result === 'exception',
                      'text-red': row[0].betting_total_result === 'miss',
                      'text-green': row[0].betting_total_result === 'hit',
                      'text-gray': row[0].betting_total_result === 'wait',
                    }"
                    >{{ row[0].betting_expected_prize | makeComma }}</span
                  >
                </td>
                <td @click="toggle(index)">
                  {{ row.length > 1 ? `${row.length}폴더` : "단폴더" }}
                </td>
              </tr>
              <transition name="fade" :key="'bettingDetail' + index">
                <tr v-if="opened.includes(index)">
                  <td colspan="9">
                    <div class="sub_table">
                      <table
                        id="sub_tableall1_2802725"
                        class="panel-body bet_dd_p"
                        cellpadding="0"
                        cellspacing="0"
                        :class="{
                          acc_list_table_in_01:
                            row[0].betting_total_result === 'exception',
                          acc_list_table_in_02:
                            row[0].betting_total_result === 'miss',
                          acc_list_table_in_04:
                            row[0].betting_total_result === 'hit',
                          acc_list_table_in_03:
                            row[0].betting_total_result === 'wait',
                        }"
                      >
                        <tbody>
                          <tr>
                            <td class="acc_list_table_in_t" width="11%">
                              경기일시
                            </td>
                            <td class="acc_list_table_in_t" width="8%">종목</td>
                            <td class="acc_list_table_in_t" width="15%">
                              리그
                            </td>
                            <td class="acc_list_table_in_t" width="18%">팀</td>
                            <td class="acc_list_table_in_t" width="10%">
                              타입
                            </td>
                            <td class="acc_list_table_in_t" width="14%">
                              배팅
                            </td>
                            <td class="acc_list_table_in_t" width="6%">
                              배당율
                            </td>
                            <td class="acc_list_table_in_t" width="6%">결과</td>
                          </tr>
                          <tr
                            v-for="(row2, index2) in row"
                            :key="'bets' + row.bets_id + index2"
                          >
                            <td class="bet_time">
                              {{
                                row2.betting_game_starttime
                                  | formatDate("YYYY-MM-DD HH:mm:ss")
                              }}
                            </td>
                            <td class="bet_event">
                              {{
                                row2.betting_game_sports_name_kr ||
                                row2.betting_game_leagues_name_en
                              }}
                            </td>
                            <td class="bet_name">
                              {{
                                row2.betting_game_leagues_name_kr ||
                                row2.betting_game_leagues_name_en
                              }}
                            </td>
                            <td class="bet_name">
                              {{
                                row2.betting_game_home_name_kr ||
                                row2.betting_game_home_name_en
                              }}
                              -
                              {{
                                row2.betting_game_away_name_kr ||
                                row2.betting_game_away_name_en
                              }}
                            </td>
                            <td class="bet_type">
                              {{
                                row2.betting_game_markets_name_kr ||
                                row2.betting_game_markets_name_en
                              }}
                              <span v-if="row2.betting_round">
                                ({{ row2.betting_round }})
                              </span>
                            </td>
                            <td class="bet_type">
                              {{ row2 | betsName2 }}
                            </td>
                            <td class="bet_type">
                              {{
                                row.length === 1
                                  ? (
                                      Number(row[0].betting_odds) +
                                      Number(row[0].betting_total_odds_penalty)
                                    ).toFixed(2)
                                  : row2.betting_odds
                              }}
                            </td>
                            <td
                              class="beting_in_btn"
                              v-if="row2.betting_status.indexOf('취소') < 0"
                            >
                              <span
                                :class="{
                                  'text-yellow':
                                    row2.betting_result === 'exception',
                                  'text-red': row2.betting_result === 'miss',
                                  'text-green': row2.betting_result === 'hit',
                                  'text-gray': row2.betting_result === 'wait',
                                }"
                              >
                                {{ row2.betting_result | bettingStatus }}</span
                              >
                            </td>
                            <td v-else>취소</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </transition>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-footer">
      <button class="submit" @click="handleSubmit">{{ submitText }}</button>
      <button class="cancel" @click="handleCancel">취소</button>
    </div>
    <transition name="fade">
      <BettingAdd
        v-if="isView"
        :open-list="openList"
        :add-betting-list="addBetting"
      />
    </transition>
  </div>
</template>

<script>
import BettingAdd from "@/components/Board/BettingAdd";

export default {
  name: "PostForm",
  components: { BettingAdd },
  props: {
    submitText: { type: String, required: true },
    payload: { type: Object, required: true },
  },
  data() {
    return {
      isView: false,
      isBettingChanged: false,
      opened: [],
      categoryOptions: [
        // { value: null, label: "선택" },
        { value: 1, label: "일반" },
        // { value: 2, label: "이벤트" },
        // { value: 3, label: "공지" },
      ],
    };
  },
  methods: {
    openList() {
      this.isView = !this.isView;
    },
    addBetting(betting) {
      // console.log('추가전 베팅', this.payload.bettings)
      console.log('추가할 베팅', betting)
      // this.payload.bettings.push([...betting]);
      // console.log('추가후 베팅', this.payload.bettings)
      
      // this.isView = false;
      // this.payload.isBettingChanged = true;
    },
    deleteBetting() {
      console.log('현재베팅내역1', this.payload.bettings)
      this.payload.bettings = [];
      console.log('현재베팅내역2',this.payload.bettings)
      this.payload.isBettingChanged = true;
    },
    handleSubmit() {
      this.$emit("submit", { ...this.payload });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    toggle: function (id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 600px) {
  .mobile {
    display: none;
  }
  .main {
    padding: 20px;
  }
  .main h1 {
    color: #b38ff5;
    text-align: center;
  }
  .form-container {
    background-color: #2b2b2b;
    padding: 20px;
    border-radius: 5px;
  }
  .form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .form-group label {
    width: 100px;
    margin-right: 10px;
  }
  .form-group input,
  .form-group textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #444444;
    border-radius: 5px;
    background-color: #1c1c1c;
    color: #ffffff;
  }
  .form-group select {
    flex: 1;
    border: 1px solid #444444;
    border-radius: 5px;
    background-color: #1c1c1c;
    color: #ffffff;
  }
  .form-group textarea {
    height: 200px;
  }
  .form-actions {
    display: block;
    justify-content: space-between;
  }
  .form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .form-actions .add-bet {
    background-color: #b38ff5;
    color: #ffffff;
  }
  .form-actions .clear-bet {
    background-color: #8b0000;
    color: #ffffff;
  }
  .form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .form-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .form-footer .submit {
    background-color: #b38ff5;
    color: #ffffff;
  }
  .form-footer .cancel {
    background-color: #444444;
    color: #ffffff;
  }
}
@media all and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .content {
    padding: 20px;
  }
  .content h1 {
    color: #b68aff;
    text-align: center;
    margin-bottom: 20px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .form-group label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 18px;
    margin-right: 10px;
  }
  .form-group input,
  .form-group textarea {
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #2c2c2c;
    border: 1px solid #444444;
    color: #ffffff;
    font-size: 16px;
  }
  .form-group select {
    width: calc(100% - 20px);
    background-color: #2c2c2c;
    border: 1px solid #444444;
    color: #ffffff;
    font-size: 16px;
  }
  .form-group textarea {
    height: 200px;
  }
  .form-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .form-actions button {
    background-color: #b68aff;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
    flex: 1 1 48%;
    box-sizing: border-box;
  }
  .form-actions button:last-child {
    background-color: #a00000;
  }
  @media (max-width: 600px) {
    .header .menu .info {
      display: none;
    }
    .header .menu .buttons button {
      padding: 5px 10px;
      margin-left: 5px;
    }
    .form-actions button {
      flex: 1 1 45%;
      gap: 3px;
    }
    .form-group label,
    .form-group select {
      display: block;
      width: 100%;
    }
    .form-group select {
      width: 100%;
    }
  }
}
</style>
