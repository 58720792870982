<template>
  <section>
    <PostForm
      :payload="article"
      :submit-text="'수정하기'"
      @update:payload="updatePayload"
      @submit="processEdit"
      @cancel="moveToDetail"
    />
  </section>
</template>

<script>
import PostForm from "@/components/FreeBoard/PostForm";
import { mapState } from "vuex";

export default {
  name: "Edit",
  components: { PostForm },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payload: this.article,
      processing: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    updatePayload(updatedPayload) {
      this.payload = updatedPayload;
    },
    processEdit(updatedPayload) {
      console.log('업데이트페이로드!!', updatedPayload)
      if (this.processing) return alert("수정 중입니다.");
      if (!updatedPayload.title || !updatedPayload.content) {
        return alert("제목과 내용을 확인해주세요.");
      }
      this.processing = true;
      this.$store
        .dispatch("EDIT_FREE", { payload: updatedPayload })
        .then(() => {
          alert("글이 수정되었습니다.");
          this.$router.push({
            name: "자유게시판-자세히",
            params: { seq: updatedPayload.seq  },
          });
        })
        .catch((err) => alert(err.response.data.error))
        .finally(() => {
          this.processing = false;
        });
    },
    moveToDetail() {
      this.$router.push({
        name: "자유게시판-자세히",
        params: { article: this.article },
      });
    },
    moveToList() {
      this.$router.push({ path: "/bbs/f/l" });
    },
  },
};
</script>


<style scoped>
@media all and (min-width: 600px) {
  .main {
    padding: 20px;
  }
  .main h1 {
    color: #b38ff5;
    text-align: center;
  }
  .form-container {
    background-color: #2b2b2b;
    padding: 20px;
    border-radius: 5px;
  }
}
</style>

<style scoped>
@media all and (min-width: 600px) {
  .mobile {
    display: none;
  }
  .main {
    padding: 20px;
  }
  .main h1 {
    color: #b38ff5;
    text-align: center;
  }
  .form-container {
    background-color: #2b2b2b;
    padding: 20px;
    border-radius: 5px;
  }
  .form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .form-group label {
    width: 100px;
    margin-right: 10px;
  }
  .form-group select,
  .form-group input,
  .form-group textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #444444;
    border-radius: 5px;
    background-color: #1c1c1c;
    color: #ffffff;
  }
  .form-group textarea {
    height: 200px;
  }
  .form-actions {
    display: flex;
    justify-content: space-between;
  }
  .form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .form-actions .add-bet {
    background-color: #b38ff5;
    color: #ffffff;
  }
  .form-actions .clear-bet {
    background-color: #8b0000;
    color: #ffffff;
  }
  .form-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .form-footer button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .form-footer .submit {
    background-color: #b38ff5;
    color: #ffffff;
  }
  .form-footer .cancel {
    background-color: #444444;
    color: #ffffff;
  }
}
@media all and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .content {
    padding: 20px;
  }
  .content h1 {
    color: #b68aff;
    text-align: center;
    margin-bottom: 20px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .form-group label {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 18px;
    margin-right: 10px;
  }
  .form-group select,
  .form-group input,
  .form-group textarea {
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #2c2c2c;
    border: 1px solid #444444;
    color: #ffffff;
    font-size: 16px;
  }
  .form-group textarea {
    height: 200px;
  }
  .form-actions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .form-actions button {
    background-color: #b68aff;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
    flex: 1 1 48%;
    box-sizing: border-box;
  }
  .form-actions button:last-child {
    background-color: #a00000;
  }
  @media (max-width: 600px) {
    .header .menu .info {
      display: none;
    }
    .header .menu .buttons button {
      padding: 5px 10px;
      margin-left: 5px;
    }
    .form-actions button {
      flex: 1 1 45%;
      gap: 3px;
    }
    .form-group label,
    .form-group select {
      display: block;
      width: 100%;
    }
    .form-group select {
      width: 100%;
    }
  }
}
</style>
